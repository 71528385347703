<template>
    <div>
		<el-table border v-loading="loading"
				  :data="dataList"
				  lazy
				  :load="loadTreeChildren"
				  row-key="menuId" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">>
			<el-table-column :label="translate('AUTHORITY.MENU_ID')" prop="menuId" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('AUTHORITY.NAME')" prop="name" width="180" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<i :class="scope.row.icon"/> {{scope.row.name}}
				</template>
			</el-table-column>
			<el-table-column :label="translate('MENU.PARENT_ID')" prop="parentId" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.SORT')" prop="sort" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('MENU.URL')" prop="url" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.CREATE_TIME')" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.OPERATION')" align="center" class-name="small-padding fixed-width" width="240" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="text" @click="handleAdd(scope.row)" v-permission="'SYS_MENU_INSERT'">{{translate('OPERATION.ADD')}}</el-button>
					<el-button size="mini" type="text" @click="handleUpdate(scope.row)" v-permission="'SYS_MENU_UPDATE'">{{translate('OPERATION.UPDATE')}}</el-button>
					<el-button size="mini" type="text" @click="handleDelete(scope.row)" v-permission="'SYS_MENU_DELETE'">{{translate('OPERATION.DELETE')}}</el-button>
				</template>
			</el-table-column>
		</el-table>

		<CustomForm :dicts="dicts"
					:on-fresh="showCustomForm"
					:isAdd="isAdd"
					:selectRow="selectRow"
					@on-close="handlerCustomFormOnclose" ></CustomForm>
	</div>
</template>

<script>
	import {UserApi} from '@/api';
	import CustomForm from "./CustomForm";
	export default {
		name: "ToolBar",
		components: {CustomForm},
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			reQuery: {
				type: Number
			},
		},
		data() {
			return {
				loading: false,
				dataList: [],
				selectRow: {},
				showCustomForm: false,
				isAdd: false
			}
		},
		mounted() {
			this.getList();
		},
		watch: {
			reQuery: function () {
				this.getList();
			}
		},
		methods: {
			handlerCustomFormOnclose() {
				this.showCustomForm = false;
				this.getList();
			},
			async getList() {
				this.loading = true;
				let result = await UserApi.menu.listMenuTree();
				this.loading = false;
				this.dataList = result.data || [];
			},
			loadTreeChildren(tree, treeNode, resolve) {
				return resolve(tree.children);
			},
			handleStatusChange(row) {
				debugger;
				let text = row.status === "DISABLE" ? "FROM.DISABLE" : "FROM.ENABLE";
				this.$confirm(this.translate('FROM.CONFIRM_OPERATION', {module: row.name, operation: text}),
					this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.menu.edit(row.menuId, {
						state: row.state
					});
					if (result.success) {
						this.getList();
					}
				}).catch(() => this.getList());
			},
			handleAdd(row) {
				this.showCustomForm = true;
				this.selectRow = row;
				this.isAdd = true;
			},
			async handleUpdate(row) {
				this.selectRow = row;
				this.showCustomForm = true;
				this.isAdd = false;
			},
			handleDelete(row){
				this.$confirm(this.translate('FROM.CONFIRM_DELETE', {module: 'MODULE.MENU', id: row.menuId}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.menu.remove(row.menuId);
					if (result.success) {
						this.Message.success(this.translate('FROM.DELETE_SUCCESS'));
						this.getList();
					}
				});
			},
		}
	};
</script>

<style scoped>

</style>
